
// Fonts
@import url('https://fonts.googleapis.com/css?family=PT+Serif:400,700');

// Variables
@import "shop_variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "animated_checkboxes";
@import "bootsrap_datepicker";

body{
    padding-bottom: 40px;
}

.marg-top{
    margin-top: 20px;
}
.marg-btm{
    margin-bottom: 20px;
}
.marg-left{
    margin-left: 20px;
}
.marg-right{
    margin-right: 20px;
}

.padding-sm{
    padding: 10px;
}

.padding{
    padding: 20px;
}

.navbar {
    border-left: none;
    border-right: none;
}

.trapeze-box{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;

    a{
        text-decoration: none;
        color: inherit;
        &:hover{
            text-decoration: none;
            color: inherit;
        }
    }

    .trapeze {
        position: relative;
        margin: 0;
        padding: 1rem 2rem 1rem 2rem;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            background: #eaeaea;
            mix-blend-mode: multiply;
            flex: 1;
            width: 100%;
            height: 100%;
        }

        &.trapeze-danger{
            color: white;

            &:before{
                background: $brand-danger;
                mix-blend-mode: color;
            }
        }

        &:after{
            content: '';
            position: absolute;
            top: 0;

            bottom: 0;
            background: white;
            mix-blend-mode: normal;
            -ms-flex: 1;
            flex: 1;
            width: 60px;
            height: 100%;
        }
        &.trapeze-left{
            padding-left: 4rem;
        }
        &.trapeze-right{
            padding-right: 4rem;
            margin-left: 20px;
        }

        &.trapeze-left:before {
            transform: skew(-45deg);

        }

        &.trapeze-right:before {
            transform: scaleX(-1) skew(45deg);
        }

        &.trapeze-left:after {
            left: -30px;
        }

        &.trapeze-right:after {
            right: -30px;
        }
    }
}

.navbar-shop {
    -webkit-box-shadow: 0px 4px 7px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 4px 7px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 4px 7px 0px rgba(0,0,0,0.25);

    .navbar-header{
        @media (max-width: $screen-phone) {
            float: left;
            img{
                height: 30px;
            }
        }
        @media (min-width: $screen-lg-min) {
            padding-left: 8.33333333%;
        }
        .navbar-brand{
            height: auto;
        }
    }

    .navbar-text{

        margin-top: 22px;
        margin-bottom: 22px;
        &.navbar-text-lead {
            margin-top: 14px;
            margin-bottom: 14px;
            color: $brand-danger;
            border-left: 2px solid $brand-danger;
            padding-left: 25px;
            font-weight: bold;

            @media (max-width: $screen-phone) {
                display: none;
            }
        }

        @media (max-width: $screen-phone) {
            margin-top: 0;
            margin-bottom: 0;
            float: right;
            padding: 10px;
        }

    }

    .box{
        @media (max-width: $screen-phone) {
            &:first-of-type{
                clear: both;
                grid-template-columns: repeat(1, 1fr);
                width: 30%;
            }
            width: 70%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: 1fr;
        }

        &.box-danger{
            background-color: $brand-danger;
            color: white;
            padding: 5px 20px;
            height: 72px;
        }
        dl.dl-horizontal {
            dd {
                line-height: 1;
                margin-left: auto !important;
            }
            margin-bottom: 0px;
            dt{
                width: 70px;
                text-align: left;
                line-height: 1;
                @media (max-width: $screen-phone) {
                    width: auto;
                }
            }
        }
    }
}
.footer{
    margin-top:10px;
    a.link{
        color: $brand-danger;
    }
}

.container{
    &:before{
        content: "";
        background-color: #f3f3f3;
        width: 100%;
        height: 550px;
        position: absolute;
        left: 0;
        top: 550px;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
}
.cover-container {
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    .cover {

        padding: 40px 20px;
        .cover-heading{
            color: $brand-danger;
            font-weight:bold;
            padding-bottom: 20px;
        }
        .lead{
            color: white;
        }
        .btn-lg {
            padding: 10px 20px;
            font-weight: bold;
        }
    }
}

.nav-events{
    width: 100%;
    padding-bottom: 1em;

    ul {
        margin: 0;
        padding: 0;
        @media (min-width: $grid-float-breakpoint) {
            display: flex;
        }
    }

    .btn{
        padding: 10px 30px;
    }
    .btn-default:active, .btn-default.active{
        background-color: $brand-danger;
        border-color: darken($brand-danger, 5%);
        color: white;
    }
}

.nav-registration{
    margin-top: 30px;
    li.active > a, li.active > a:hover, li.active > a:focus{
        color: $text-color;
        background-color: transparent;
        border-color: $brand-danger;
    }
    li > a{
        color: $text-color;
    }

    li > a:hover, li > a:focus {
        background-color: transparent;
    }
}

.nav-sector{
    margin-top: 30px;
    li{
        width: 50%;
        text-align: center;
    }
    li.active > a, li.active > a:hover, li.active > a:focus{
        color: white;
        background-color: $brand-danger;
        border-color: darken($brand-danger, 5%);
    }
    li > a{
        color: $text-color;
    }

    li > a:hover, li > a:focus {
        background-color: transparent;
    }
}

.shop-container{
}
.event-container{
    .event{
        @media (min-width: $grid-float-breakpoint) {
            display: flex;

            .event-cart {
                flex: 2;
            }
            .event-description{
                flex:1;
                padding: 0px;
            }
        }

        .event-description{
            padding: 30px 20px 0px 0px;
            vertical-align: middle;
            letter-spacing: 1px;
        }

        .form-group-ticket{
            text-align: center;
            margin-bottom: 30px;
            display: flex;
            align-items: center;

            @media (max-width: $screen-md) {
                flex-direction: column;
            }

            label{
                margin-left: 15px;
                font-size: 15px;
            }
        }
    }
}

.panel-auth, .panel-summary, .panel-main{
    margin-top: 30px;
}

.panel-main{
    .lead{ margin: 20px 0px;}
}

.nav.navbar-center{
    text-align: center;
    width: 100%;
    .dropdown{
        float: none !important;
        display: inline-block;
        .dropdown-menu{
            width: 100%;
            text-align: center;
        }
    }
}

.payments-container{
    h3.page-header{
        margin-top: 0px;
    }

    .ac-custom{
        ul{
            display: flex;
        }
        li{
            padding: 1em 0;
        }
        label{
            font-size: $font-size-h3;
            font-weight: normal;
            padding: 0 0 0 60px;
        }

        input[type="checkbox"], input[type="radio"], label::before{
            width: 40px;
            height: 40px;
            top: 50%;
            left: 3px;
            margin-top: -20px;
            position: absolute;
            cursor: pointer;
        }

        label.ac-multiline::before{
            margin-top: -10px;
        }


        label::before{
            border: 3px solid lighten( $text-color, 15%);
        }
    }
}
.invoice-request{
    margin-top: 16px;
}
.invoice-container{
    .invoice-request-block{
        .btn-default:active, .btn-default.active{
            background-color: $brand-danger;
            border-color: darken($brand-danger, 5%);
            color: white;
        }
    }
    .invoice{
        display: none;
    }

    .page-header{
        margin-top: 0px;
    }
}

.nav-event-description{
    .image-container{
        height: 200px;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    p{
        font-size: 12px;
    }
}

#loading_modal{
    display:none;
    overflow: hidden;

    .loading_background{
        position: fixed;
        background:#000;
        height:100%;
        width:100%;
        opacity: 0.5;
        z-index:10000;
    }
    .loading_icon{
        position: fixed;
        top:calc(50% - 70px);
        left:calc(50% - 70px);
        z-index:10005;
        i{
            font-size:140px;
        }
    }
}

.bg-danger, .form-control.bg-danger{
    background-color: #f2dede;
}

.alert-default{
    color: black;
    background-color: #cdcdcd;
    border-color: #787270;
}

.no-margin{
    margin: 0;
}
.panel-title{
    h2,h3,h4,h5 {
        overflow: hidden;
        text-align: center;
        text-transform: uppercase;
        color: lighten($brand-danger, 10%);

        &:before,&:after{
            background-color: lighten($brand-danger, 10%);
            content: "";
            display: inline-block;
            height: 1px;
            position: relative;
            vertical-align: middle;
            width: 50%;
        }

        &:before {
            right: 0.5em;
            margin-left: -50%;
        }

        &:after {
            left: 0.5em;
            margin-right: -50%;
        }
    }
}

.panel-list{
    display: grid;
    overflow: hidden;

    @media (min-width: $screen-md) {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
    }
    @media (min-width: $screen-lg) {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
    }
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 20px;

    &.full-grid{
        grid-template-columns: repeat(1, 1fr);

        form{
            @media (max-width: $screen-phone) {
                padding: 0;

                .input-group{
                    display: block;
                }
            }
            flex: 1;
            padding: 20px;
        }

        .panel-packet {
            color: inherit;
            background-color: inherit;
            &:hover {
                text-decoration: none;
                cursor: default;
                background-color: inherit;
            }
        }
    }

    p{
        font-size: 12px;
        @media(min-width: $screen-lg){
            font-size: 14px;
        }
    }
    .panel {
        border: none;
        border-radius: 0;
        box-shadow:none;

        &.panel-packet {
            &:hover {
                text-decoration: none;
                cursor: pointer;
            }

            color: white;
            display: flex;
            position: relative;

            &.panel-sold-out{
                &:hover{
                    text-decoration: none;
                    cursor:default;
                }
                .panel-body {
                    .panel-event {
                        opacity: 0.5;
                    }
                }
            }

            .panel-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                h3 {
                    flex-shrink: 0;
                }

                .panel-event{
                    background: $brand-danger;
                    background: linear-gradient(180deg, $brand-danger 0%, lighten($brand-danger, 15%) 100%);
                    width: 100%;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    &:before{
                        content: "";
                        max-width: 100%;
                        top: 15px;
                        left: 15px;
                        position: absolute;
                        height: 12px;
                        background: white;
                        right: 15px;
                        bottom: 0px;
                    }
                    &.grey-corners{
                        &:before{
                            background: #f3f3f3;
                        }
                    }
                    &:after{
                        content: "";
                        max-width: 100%;
                        top: 12px;
                        left: 15px;
                        right: 15px;
                        bottom: 0px;
                        position: absolute;
                        height: 30px;
                        background: radial-gradient(circle at 0 0, rgba(204,0,0,0) 14px, #9d0627 15px) top left, radial-gradient(circle at 100% 0, rgba(204,0,0,0) 14px, #9d0627 15px) top right;
                        background-size: 51% 51%;
                        background-repeat: no-repeat;

                    }

                    .price{
                        span {
                            color: $state-warning-text;
                            font-weight: bold;
                            font-size: 72px;
                        }
                    }
                }
                .panel-buy{
                    width: 100%;
                    text-align: center;
                    padding: 20px;

                    background: linear-gradient(135deg, transparent 0px, white 0) top left, linear-gradient(225deg, transparent 0px, white 0) top right, radial-gradient(circle at 0 100%, rgba(204,0,0,0) 14px, white 15px) bottom left, radial-gradient(circle at 100% 100%, rgba(204,0,0,0) 14px, white 15px) bottom right;
                    background-size: 51% 51%;
                    background-repeat: no-repeat;
                }
            }

            &.panel-shadow {
                &:after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    box-shadow: 0px 20px 10px 0px rgba(0, 0, 0, 0.8);
                    bottom: 15px;
                    width: 70%;
                    height: 5%;
                    border-radius: 100%;
                    left: 15%;
                    right: 15%;
                }
            }
        }
    }

    .panel-description{
        @media (min-width: $screen-md) {
            grid-column: 3/ span 2;
        }
        grid-column: 1/ span 2;
        background-color: #cdcdcd;
        color: black;
        border: 1px solid black;
        display: flex;

        &:hover{
            text-decoration: none;
            cursor: default;
            background-color: #cdcdcd;
        }
    }

    .trapeze-box{
        position: absolute;
        left: -30px;
        font-size: 20px;
        .trapeze-left{
            padding-right: 5rem;
            padding-left: 5rem;
        }
    }
}

blockquote{
    border-left: 5px solid $brand-danger;
    background-color: #99072605;
}

@media (max-width: $screen-phone) {
    .page-header {
        margin: 0px;
    }
    h1, .h1 { font-size: ceil($font-size-h1 * 0.7); }
    h2, .h2 { font-size: ceil($font-size-h2 * 0.7); }
    h3, .h3 { font-size: ceil($font-size-h3 * 0.7); }
}

.grid{
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(1,1fr);

    &.grid-column-gap{
        grid-column-gap: 10px;
    }

    &.grid-row-gap{
        grid-row-gap: 10px;
    }
}

@media (min-width: $screen-sm-min) {
    .grid-sm-2{
        grid-template-columns: repeat(2,1fr);
    }
    .grid-sm-3{
        grid-template-columns: repeat(3,1fr);
    }
    .grid-sm-4{
        grid-template-columns: repeat(4,1fr);
    }
    .grid-sm-5{
        grid-template-columns: repeat(5,1fr);
    }
    .grid-sm-6{
        grid-template-columns: repeat(6,1fr);
    }
}

@media (min-width: $screen-md-min) {
    .grid-md-2{
        grid-template-columns: repeat(2,1fr);
    }
    .grid-md-3{
        grid-template-columns: repeat(3,1fr);
    }
    .grid-md-4{
        grid-template-columns: repeat(4,1fr);
    }
    .grid-md-5{
        grid-template-columns: repeat(5,1fr);
    }
    .grid-md-6{
        grid-template-columns: repeat(6,1fr);
    }
}

@media (min-width: $screen-lg-min) {
    .grid-lg-2{
        grid-template-columns: repeat(2,1fr);
    }
    .grid-lg-3{
        grid-template-columns: repeat(3,1fr);
    }
    .grid-lg-4{
        grid-template-columns: repeat(4,1fr);
    }
    .grid-lg-5{
        grid-template-columns: repeat(5,1fr);
    }
    .grid-lg-6{
        grid-template-columns: repeat(6,1fr);
    }
    .grid-lg-7{
        grid-template-columns: repeat(7,1fr);
    }
    .grid-lg-8{
        grid-template-columns: repeat(8,1fr);
    }
}

// Colours -----------------------------------------
$grey:  #777;
$grey2: #dfe3e4;
$white: #fff;

// Progress Bar ------------------------------------
section.progress-container {
    display: flex;
    .empty-track{
        width: calc(100%/3);
        height: 5px;
        background-color: #dfe3e4;
        z-index: -1;
        margin-top: 6px;
    }
    .progress-steps {
        position: relative;
        display: flex;
        width: calc(100%/3);

        a{
            text-decoration: none;
            &:hover{
                text-shadow:0px 0px 7px rgba(153,7,38,0.3);
                -webkit-transition: all .5s;
                -moz-transition: all .5s;
                -o-transition: all .5s;
                transition: all .5s;
            }
        }

        // Background Track
        .progress-track {
            position: absolute;
            top: 6px;
            width: 100%;
            height: 5px;
            background-color: $grey2;
            z-index: -1;
        }

        // Each Step on the Progress Bar
        .progress-step {
            position: relative;
            width: 100%;
            font-size: 12px;
            text-align: center;

            // Hide the final step's progress bar
            &:last-child:after {
                background: $white;
                background: #fff;
                top: 6px;
                width: 100%;
            }

            // Step's circle in default state
            &:before {
                font-family: FontAwesome;
                content: "";
                display: flex;
                margin: 0 auto;
                margin-bottom: 10px;
                width: 20px;
                height: 20px;
                background: $white;
                border: 4px solid $grey2;
                border-radius: 100%;
                color: $white;
            }

            // Step's progress bar in default state
            &:after {
                content: "";
                position: absolute;
                top: 6px;
                left: 50%;
                width: 0%;
                transition: width 1s ease-in;
                height: 5px;
                background: $grey2;
                z-index: -1;
            }

            // Step's active state
            &.is-active {
                color: $brand-danger;

                &:before {
                    border: 4px solid $grey;
                    animation: pulse 2s infinite;
                }
            }

            // Step's complete state
            &.is-complete {
                color: $brand-danger;

                // Step's circle in complete state
                &:before {
                    background: $brand-danger;
                    border: 4px solid transparent;
                }

                // Step's progress bar in complete state
                &:after {
                    background: $brand-danger;
                    animation: nextStep 1s;
                    animation-fill-mode: forwards;
                }
            }
        }
    }
}

// Animations --------------------------------------
// Pulse animation for Step's circle in active state
@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(153,7,38, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(153,7,38, 0.2);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(153,7,38, 0);
    }
}

// Progressing to next step animation for Step's progress bar
@keyframes nextStep {
    0% { width: 0%; }
    100% { width: 100%; }
}

.d-flex{
    display: flex !important;
    @media (min-width: 767px) {
        &.visible-xs {
            display: none !important;
        }
    }
}