//colors
$black_15: rgba(0, 0, 0, 0.15);
$black_20: rgba(0, 0, 0, 0.2);
$white: #fff;
$black_10: rgba(0, 0, 0, 0.1);
$color_tapa_approx: #777777;
$color_gallery_approx: #eeeeee;
$black: #000;
$color_link_water_approx: #d9edf7;
$color_seagull_approx: #85c5e5;
$color_regent_st_blue_approx: #afd9ee;
$color_curious_blue_approx: #298fc2;
$color_shakespeare_approx: #52addb;
$color_cornflower_approx: #91cbe8;
$color_cherokee_approx: #ffdb99;
$color_saffron_approx: #ffb733;
$color_golden_tainoi_approx: #ffc966;
$color_pirate_gold_approx: #b37400;
$color_california_approx: #f59e00;
$color_yellow_orange_approx: #ffbc42;
$color_pink_swan_approx: #bbbbbb;
$color_quill_gray_approx: #d5d5d5;
$color_concord_approx: #7c7c7c;
$color_star_dust_approx: #9d9d9d;
$color_pale_slate_approx: #c3c3c3;
$color_mystic_approx: #e4eef3;
$color_casper_approx: #9dc1d3;
$color_ziggurat_approx: #c1d7e3;
$color_wedgewood_approx: #4b88a6;
$color_glacier_approx: #73a6c0;
$color_pigeon_post_approx: #a8c8d8;
$color_rob_roy_approx: #f7ca77;
$color_buttercup_approx: #f1a417;
$color_casablanca_approx: #f4b747;
$color_rusty_nail_approx: #815608;
$color_fuel_yellow_approx: #f2aa25;
$color_fuscous_gray_approx: #555555;
$black_25: rgba(0, 0, 0, 0.25);
$color_chicago_approx: #5e5e5e;
$color_hunter_green_approx: #161616;
$color_tuatara_approx: #373737;
$color_gravel_approx: #4c4c4c;
$color_steel_blue_approx: #337ab7;
$color_astral_approx: #2e6da4;
$color_st_tropaz_approx: #286090;
$color_big_stone_approx: #122b40;
$color_chathams_blue_approx: #204d74;
$color_burgundy_approx: #990726;
$color_monarch_approx: #880622;

//@extend-elements
//original selectors
//.datepicker table tr td, .datepicker table tr th
%extend_1 {
    text-align: center;
    width: 100px;
    height: 40px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 4px;
    border: none;
}

//original selectors
//.datepicker table tr td.disabled, .datepicker table tr td.disabled:hover
%extend_2 {
    background: none;
    color: $color_tapa_approx;
    cursor: default;
    font-weight: normal;
}

//original selectors
//.datepicker table tr td.highlighted:focus, .datepicker table tr td.highlighted.focus
%extend_3 {
    color: $black;
    background-color: $color_regent_st_blue_approx;
    border-color: $color_curious_blue_approx;
}

//original selectors
//.datepicker table tr td.highlighted:active, .datepicker table tr td.highlighted.active
%extend_4 {
    color: $black;
    background-color: $color_regent_st_blue_approx;
    border-color: $color_shakespeare_approx;
}

//original selectors
//.datepicker table tr td.highlighted:active:hover, .datepicker table tr td.highlighted.active:hover, .datepicker table tr td.highlighted:active:focus, .datepicker table tr td.highlighted.active:focus, .datepicker table tr td.highlighted:active.focus, .datepicker table tr td.highlighted.active.focus
%extend_5 {
    color: $black;
    background-color: $color_cornflower_approx;
    border-color: $color_curious_blue_approx;
}

//original selectors
//.datepicker table tr td.today:focus, .datepicker table tr td.today.focus
%extend_6 {
    color: $black;
    background-color: $color_golden_tainoi_approx;
    border-color: $color_pirate_gold_approx;
}

//original selectors
//.datepicker table tr td.today:active, .datepicker table tr td.today.active
%extend_7 {
    color: $black;
    background-color: $color_golden_tainoi_approx;
    border-color: $color_california_approx;
}

//original selectors
//.datepicker table tr td.today:active:hover, .datepicker table tr td.today.active:hover, .datepicker table tr td.today:active:focus, .datepicker table tr td.today.active:focus, .datepicker table tr td.today:active.focus, .datepicker table tr td.today.active.focus
%extend_8 {
    color: $black;
    background-color: $color_yellow_orange_approx;
    border-color: $color_pirate_gold_approx;
}

//original selectors
//.datepicker table tr td.range:focus, .datepicker table tr td.range.focus
%extend_9 {
    color: $black;
    background-color: $color_quill_gray_approx;
    border-color: $color_concord_approx;
}

//original selectors
//.datepicker table tr td.range:active, .datepicker table tr td.range.active
%extend_10 {
    color: $black;
    background-color: $color_quill_gray_approx;
    border-color: $color_star_dust_approx;
}

//original selectors
//.datepicker table tr td.range:active:hover, .datepicker table tr td.range.active:hover, .datepicker table tr td.range:active:focus, .datepicker table tr td.range.active:focus, .datepicker table tr td.range:active.focus, .datepicker table tr td.range.active.focus
%extend_11 {
    color: $black;
    background-color: $color_pale_slate_approx;
    border-color: $color_concord_approx;
}

//original selectors
//.datepicker table tr td.range.highlighted:focus, .datepicker table tr td.range.highlighted.focus
%extend_12 {
    color: $black;
    background-color: $color_ziggurat_approx;
    border-color: $color_wedgewood_approx;
}

//original selectors
//.datepicker table tr td.range.highlighted:active, .datepicker table tr td.range.highlighted.active
%extend_13 {
    color: $black;
    background-color: $color_ziggurat_approx;
    border-color: $color_glacier_approx;
}

//original selectors
//.datepicker table tr td.range.highlighted:active:hover, .datepicker table tr td.range.highlighted.active:hover, .datepicker table tr td.range.highlighted:active:focus, .datepicker table tr td.range.highlighted.active:focus, .datepicker table tr td.range.highlighted:active.focus, .datepicker table tr td.range.highlighted.active.focus
%extend_14 {
    color: $black;
    background-color: $color_pigeon_post_approx;
    border-color: $color_wedgewood_approx;
}

//original selectors
//.datepicker table tr td.range.today:focus, .datepicker table tr td.range.today.focus
%extend_15 {
    color: $black;
    background-color: $color_casablanca_approx;
    border-color: $color_rusty_nail_approx;
}

//original selectors
//.datepicker table tr td.range.today:active, .datepicker table tr td.range.today.active
%extend_16 {
    color: $black;
    background-color: $color_casablanca_approx;
    border-color: $color_pirate_gold_approx;
}

//original selectors
//.datepicker table tr td.range.today:active:hover, .datepicker table tr td.range.today.active:hover, .datepicker table tr td.range.today:active:focus, .datepicker table tr td.range.today.active:focus, .datepicker table tr td.range.today:active.focus, .datepicker table tr td.range.today.active.focus
%extend_17 {
    color: $black;
    background-color: $color_fuel_yellow_approx;
    border-color: $color_rusty_nail_approx;
}

//original selectors
//.datepicker table tr td.selected, .datepicker table tr td.selected.highlighted
%extend_18 {
    color: $white;
    background-color: $color_tapa_approx;
    border-color: $color_fuscous_gray_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 -1px 0 $black_25;
}

//original selectors
//.datepicker table tr td.selected:focus, .datepicker table tr td.selected.highlighted:focus, .datepicker table tr td.selected.focus, .datepicker table tr td.selected.highlighted.focus
%extend_19 {
    color: $white;
    background-color: $color_chicago_approx;
    border-color: $color_hunter_green_approx;
}

//original selectors
//.datepicker table tr td.selected:hover, .datepicker table tr td.selected.highlighted:hover
%extend_20 {
    color: $white;
    background-color: $color_chicago_approx;
    border-color: $color_tuatara_approx;
}

//original selectors
//.datepicker table tr td.selected:active, .datepicker table tr td.selected.highlighted:active, .datepicker table tr td.selected.active, .datepicker table tr td.selected.highlighted.active
%extend_21 {
    color: $white;
    background-color: $color_chicago_approx;
    border-color: $color_tuatara_approx;
}

//original selectors
//.datepicker table tr td.selected:active:hover, .datepicker table tr td.selected.highlighted:active:hover, .datepicker table tr td.selected.active:hover, .datepicker table tr td.selected.highlighted.active:hover, .datepicker table tr td.selected:active:focus, .datepicker table tr td.selected.highlighted:active:focus, .datepicker table tr td.selected.active:focus, .datepicker table tr td.selected.highlighted.active:focus, .datepicker table tr td.selected:active.focus, .datepicker table tr td.selected.highlighted:active.focus, .datepicker table tr td.selected.active.focus, .datepicker table tr td.selected.highlighted.active.focus
%extend_22 {
    color: $white;
    background-color: $color_gravel_approx;
    border-color: $color_hunter_green_approx;
}

//original selectors
//.datepicker table tr td.active, .datepicker table tr td.active.highlighted
%extend_23 {
    color: $white;
    background-color: $color_steel_blue_approx;
    border-color: $color_astral_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 -1px 0 $black_25;
}

//original selectors
//.datepicker table tr td.active:focus, .datepicker table tr td.active.highlighted:focus, .datepicker table tr td.active.focus, .datepicker table tr td.active.highlighted.focus
%extend_24 {
    color: $white;
    background-color: $color_st_tropaz_approx;
    border-color: $color_big_stone_approx;
}

//original selectors
//.datepicker table tr td.active:hover, .datepicker table tr td.active.highlighted:hover
%extend_25 {
    color: $white;
    background-color: $color_st_tropaz_approx;
    border-color: $color_chathams_blue_approx;
}

//original selectors
//.datepicker table tr td.active:active, .datepicker table tr td.active.highlighted:active, .datepicker table tr td.active.active, .datepicker table tr td.active.highlighted.active
%extend_26 {
    color: $white;
    background-color: $color_burgundy_approx;
    border-color: $color_monarch_approx;
}

//original selectors
//.datepicker table tr td.active:active:hover, .datepicker table tr td.active.highlighted:active:hover, .datepicker table tr td.active.active:hover, .datepicker table tr td.active.highlighted.active:hover, .datepicker table tr td.active:active:focus, .datepicker table tr td.active.highlighted:active:focus, .datepicker table tr td.active.active:focus, .datepicker table tr td.active.highlighted.active:focus, .datepicker table tr td.active:active.focus, .datepicker table tr td.active.highlighted:active.focus, .datepicker table tr td.active.active.focus, .datepicker table tr td.active.highlighted.active.focus
%extend_27 {
    color: $white;
    background-color: $color_burgundy_approx;
    border-color: $color_monarch_approx;
}

//original selectors
//.datepicker table tr td span.disabled, .datepicker table tr td span.disabled:hover
%extend_28 {
    background: none;
    color: $color_tapa_approx;
    cursor: default;
}

//original selectors
//.datepicker table tr td span.active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover
%extend_29 {
    color: $white;
    background-color: $color_steel_blue_approx;
    border-color: $color_astral_approx;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 0 -1px 0 $black_25;
}

//original selectors
//.datepicker table tr td span.active:focus, .datepicker table tr td span.active:hover:focus, .datepicker table tr td span.active.disabled:focus, .datepicker table tr td span.active.disabled:hover:focus, .datepicker table tr td span.active.focus, .datepicker table tr td span.active:hover.focus, .datepicker table tr td span.active.disabled.focus, .datepicker table tr td span.active.disabled:hover.focus
%extend_30 {
    color: $white;
    background-color: $color_st_tropaz_approx;
    border-color: $color_big_stone_approx;
}

//original selectors
//.datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover:hover, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled:hover:hover
%extend_31 {
    color: $white;
    background-color: $color_st_tropaz_approx;
    border-color: $color_chathams_blue_approx;
}

//original selectors
//.datepicker table tr td span.active:active, .datepicker table tr td span.active:hover:active, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active.active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:hover.active
%extend_32 {
    color: $white;
    background-color: $color_st_tropaz_approx;
    border-color: $color_chathams_blue_approx;
}

//original selectors
//.datepicker table tr td span.active:active:hover, .datepicker table tr td span.active:hover:active:hover, .datepicker table tr td span.active.disabled:active:hover, .datepicker table tr td span.active.disabled:hover:active:hover, .datepicker table tr td span.active.active:hover, .datepicker table tr td span.active:hover.active:hover, .datepicker table tr td span.active.disabled.active:hover, .datepicker table tr td span.active.disabled:hover.active:hover, .datepicker table tr td span.active:active:focus, .datepicker table tr td span.active:hover:active:focus, .datepicker table tr td span.active.disabled:active:focus, .datepicker table tr td span.active.disabled:hover:active:focus, .datepicker table tr td span.active.active:focus, .datepicker table tr td span.active:hover.active:focus, .datepicker table tr td span.active.disabled.active:focus, .datepicker table tr td span.active.disabled:hover.active:focus, .datepicker table tr td span.active:active.focus, .datepicker table tr td span.active:hover:active.focus, .datepicker table tr td span.active.disabled:active.focus, .datepicker table tr td span.active.disabled:hover:active.focus, .datepicker table tr td span.active.active.focus, .datepicker table tr td span.active:hover.active.focus, .datepicker table tr td span.active.disabled.active.focus, .datepicker table tr td span.active.disabled:hover.active.focus
%extend_33 {
    color: $white;
    background-color: $color_chathams_blue_approx;
    border-color: $color_big_stone_approx;
}


.datepicker {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 4px;
    direction: ltr;
    table {

        border: 1px solid $color_pink_swan_approx;
        border-collapse: inherit;
        border-radius: 4px;

        width: 100%;
        max-width: 800px;
        margin: auto;
        //Instead of the line below you could use @include user-select($select)
        user-select: none;
        tr {
            td {
                @extend %extend_1;
                font-weight: bold;
                background: transparent;
                &.old {
                    color: $color_tapa_approx;
                }
                &.new {
                    color: $color_tapa_approx;
                }
                &.focused {
                    background: $color_gallery_approx;
                    cursor: pointer;
                }
                &.disabled {
                    @extend %extend_2;
                    &:hover {
                        @extend %extend_2;
                    }
                }
                &.highlighted {
                    color: $black;
                    background-color: $color_link_water_approx;
                    border-color: $color_seagull_approx;
                    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
                    border-radius: 0;
                    &:focus {
                        @extend %extend_3;
                    }
                    &.focus {
                        @extend %extend_3;
                    }
                    &:hover {
                        color: $black;
                        background-color: $color_regent_st_blue_approx;
                        border-color: $color_shakespeare_approx;
                    }
                    &:active {
                        @extend %extend_4;
                        &:hover {
                            @extend %extend_5;
                        }
                        &:focus {
                            @extend %extend_5;
                        }
                        &.focus {
                            @extend %extend_5;
                        }
                    }
                    &.active {
                        @extend %extend_4;
                        &:hover {
                            @extend %extend_5;
                        }
                        &:focus {
                            @extend %extend_5;
                        }
                        &.focus {
                            @extend %extend_5;
                        }
                    }
                    &.disabled {
                        background: $color_link_water_approx;
                        color: $color_tapa_approx;
                        &:hover {
                            background-color: $color_link_water_approx;
                            border-color: $color_seagull_approx;
                        }
                        &:focus {
                            background-color: $color_link_water_approx;
                            border-color: $color_seagull_approx;
                        }
                        &.focus {
                            background-color: $color_link_water_approx;
                            border-color: $color_seagull_approx;
                        }
                        &:active {
                            background: $color_link_water_approx;
                            color: $color_tapa_approx;
                        }
                    }
                    &.focused {
                        background: $color_regent_st_blue_approx;
                    }
                }
                &.highlighted[disabled] {
                    &:hover {
                        background-color: $color_link_water_approx;
                        border-color: $color_seagull_approx;
                    }
                    &:focus {
                        background-color: $color_link_water_approx;
                        border-color: $color_seagull_approx;
                    }
                    &.focus {
                        background-color: $color_link_water_approx;
                        border-color: $color_seagull_approx;
                    }
                }
                &.today {
                    color: $black;
                    background-color: $color_cherokee_approx;
                    border-color: $color_saffron_approx;
                    &:focus {
                        @extend %extend_6;
                    }
                    &.focus {
                        @extend %extend_6;
                    }
                    &:hover {
                        color: $black;
                        background-color: $color_golden_tainoi_approx;
                        border-color: $color_california_approx;
                    }
                    &:active {
                        @extend %extend_7;
                        &:hover {
                            @extend %extend_8;
                        }
                        &:focus {
                            @extend %extend_8;
                        }
                        &.focus {
                            @extend %extend_8;
                        }
                    }
                    &.active {
                        @extend %extend_7;
                        &:hover {
                            @extend %extend_8;
                        }
                        &:focus {
                            @extend %extend_8;
                        }
                        &.focus {
                            @extend %extend_8;
                        }
                    }
                    &.disabled {
                        background: $color_cherokee_approx;
                        color: $color_tapa_approx;
                        &:hover {
                            background-color: $color_cherokee_approx;
                            border-color: $color_saffron_approx;
                        }
                        &:focus {
                            background-color: $color_cherokee_approx;
                            border-color: $color_saffron_approx;
                        }
                        &.focus {
                            background-color: $color_cherokee_approx;
                            border-color: $color_saffron_approx;
                        }
                        &:active {
                            background: $color_cherokee_approx;
                            color: $color_tapa_approx;
                        }
                    }
                    &.focused {
                        background: $color_golden_tainoi_approx;
                    }
                }
                &.today[disabled] {
                    &:hover {
                        background-color: $color_cherokee_approx;
                        border-color: $color_saffron_approx;
                    }
                    &:focus {
                        background-color: $color_cherokee_approx;
                        border-color: $color_saffron_approx;
                    }
                    &.focus {
                        background-color: $color_cherokee_approx;
                        border-color: $color_saffron_approx;
                    }
                }
                &.range {
                    color: $black;
                    background-color: $color_gallery_approx;
                    border-color: $color_pink_swan_approx;
                    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
                    border-radius: 0;
                    &:focus {
                        @extend %extend_9;
                    }
                    &.focus {
                        @extend %extend_9;
                    }
                    &:hover {
                        color: $black;
                        background-color: $color_quill_gray_approx;
                        border-color: $color_star_dust_approx;
                    }
                    &:active {
                        @extend %extend_10;
                        &:hover {
                            @extend %extend_11;
                        }
                        &:focus {
                            @extend %extend_11;
                        }
                        &.focus {
                            @extend %extend_11;
                        }
                    }
                    &.active {
                        @extend %extend_10;
                        &:hover {
                            @extend %extend_11;
                        }
                        &:focus {
                            @extend %extend_11;
                        }
                        &.focus {
                            @extend %extend_11;
                        }
                    }
                    &.disabled {
                        background: $color_gallery_approx;
                        color: $color_tapa_approx;
                        &:hover {
                            background-color: $color_gallery_approx;
                            border-color: $color_pink_swan_approx;
                        }
                        &:focus {
                            background-color: $color_gallery_approx;
                            border-color: $color_pink_swan_approx;
                        }
                        &.focus {
                            background-color: $color_gallery_approx;
                            border-color: $color_pink_swan_approx;
                        }
                        &:active {
                            background: $color_gallery_approx;
                            color: $color_tapa_approx;
                        }
                    }
                    &.focused {
                        background: $color_quill_gray_approx;
                    }
                    &.highlighted {
                        color: $black;
                        background-color: $color_mystic_approx;
                        border-color: $color_casper_approx;
                        &:focus {
                            @extend %extend_12;
                        }
                        &.focus {
                            @extend %extend_12;
                        }
                        &:hover {
                            color: $black;
                            background-color: $color_ziggurat_approx;
                            border-color: $color_glacier_approx;
                        }
                        &:active {
                            @extend %extend_13;
                            &:hover {
                                @extend %extend_14;
                            }
                            &:focus {
                                @extend %extend_14;
                            }
                            &.focus {
                                @extend %extend_14;
                            }
                        }
                        &.active {
                            @extend %extend_13;
                            &:hover {
                                @extend %extend_14;
                            }
                            &:focus {
                                @extend %extend_14;
                            }
                            &.focus {
                                @extend %extend_14;
                            }
                        }
                        &.disabled {
                            background: $color_mystic_approx;
                            color: $color_tapa_approx;
                            &:hover {
                                background-color: $color_mystic_approx;
                                border-color: $color_casper_approx;
                            }
                            &:focus {
                                background-color: $color_mystic_approx;
                                border-color: $color_casper_approx;
                            }
                            &.focus {
                                background-color: $color_mystic_approx;
                                border-color: $color_casper_approx;
                            }
                            &:active {
                                background: $color_mystic_approx;
                                color: $color_tapa_approx;
                            }
                        }
                        &.focused {
                            background: $color_ziggurat_approx;
                        }
                    }
                    &.highlighted[disabled] {
                        &:hover {
                            background-color: $color_mystic_approx;
                            border-color: $color_casper_approx;
                        }
                        &:focus {
                            background-color: $color_mystic_approx;
                            border-color: $color_casper_approx;
                        }
                        &.focus {
                            background-color: $color_mystic_approx;
                            border-color: $color_casper_approx;
                        }
                    }
                    &.today {
                        color: $black;
                        background-color: $color_rob_roy_approx;
                        border-color: $color_buttercup_approx;
                        &:focus {
                            @extend %extend_15;
                        }
                        &.focus {
                            @extend %extend_15;
                        }
                        &:hover {
                            color: $black;
                            background-color: $color_casablanca_approx;
                            border-color: $color_pirate_gold_approx;
                        }
                        &:active {
                            @extend %extend_16;
                            &:hover {
                                @extend %extend_17;
                            }
                            &:focus {
                                @extend %extend_17;
                            }
                            &.focus {
                                @extend %extend_17;
                            }
                        }
                        &.active {
                            @extend %extend_16;
                            &:hover {
                                @extend %extend_17;
                            }
                            &:focus {
                                @extend %extend_17;
                            }
                            &.focus {
                                @extend %extend_17;
                            }
                        }
                        &.disabled {
                            background: $color_rob_roy_approx;
                            color: $color_tapa_approx;
                            &:hover {
                                background-color: $color_rob_roy_approx;
                                border-color: $color_buttercup_approx;
                            }
                            &:focus {
                                background-color: $color_rob_roy_approx;
                                border-color: $color_buttercup_approx;
                            }
                            &.focus {
                                background-color: $color_rob_roy_approx;
                                border-color: $color_buttercup_approx;
                            }
                            &:active {
                                background: $color_rob_roy_approx;
                                color: $color_tapa_approx;
                            }
                        }
                    }
                    &.today[disabled] {
                        &:hover {
                            background-color: $color_rob_roy_approx;
                            border-color: $color_buttercup_approx;
                        }
                        &:focus {
                            background-color: $color_rob_roy_approx;
                            border-color: $color_buttercup_approx;
                        }
                        &.focus {
                            background-color: $color_rob_roy_approx;
                            border-color: $color_buttercup_approx;
                        }
                    }
                }
                &.range[disabled] {
                    &:hover {
                        background-color: $color_gallery_approx;
                        border-color: $color_pink_swan_approx;
                    }
                    &:focus {
                        background-color: $color_gallery_approx;
                        border-color: $color_pink_swan_approx;
                    }
                    &.focus {
                        background-color: $color_gallery_approx;
                        border-color: $color_pink_swan_approx;
                    }
                }
                &.selected {
                    @extend %extend_18;
                    &.highlighted {
                        @extend %extend_18;
                        &:focus {
                            @extend %extend_19;
                        }
                        &.focus {
                            @extend %extend_19;
                        }
                        &:hover {
                            @extend %extend_20;
                        }
                        &:active {
                            @extend %extend_21;
                            &:hover {
                                @extend %extend_22;
                            }
                            &:focus {
                                @extend %extend_22;
                            }
                            &.focus {
                                @extend %extend_22;
                            }
                        }
                        &.active {
                            @extend %extend_21;
                            &:hover {
                                @extend %extend_22;
                            }
                            &:focus {
                                @extend %extend_22;
                            }
                            &.focus {
                                @extend %extend_22;
                            }
                        }
                        &.disabled {
                            &:hover {
                                background-color: $color_tapa_approx;
                                border-color: $color_fuscous_gray_approx;
                            }
                            &:focus {
                                background-color: $color_tapa_approx;
                                border-color: $color_fuscous_gray_approx;
                            }
                            &.focus {
                                background-color: $color_tapa_approx;
                                border-color: $color_fuscous_gray_approx;
                            }
                        }
                    }
                    &:focus {
                        @extend %extend_19;
                    }
                    &.focus {
                        @extend %extend_19;
                    }
                    &:hover {
                        @extend %extend_20;
                    }
                    &:active {
                        @extend %extend_21;
                        &:hover {
                            @extend %extend_22;
                        }
                        &:focus {
                            @extend %extend_22;
                        }
                        &.focus {
                            @extend %extend_22;
                        }
                    }
                    &.active {
                        @extend %extend_21;
                        &:hover {
                            @extend %extend_22;
                        }
                        &:focus {
                            @extend %extend_22;
                        }
                        &.focus {
                            @extend %extend_22;
                        }
                    }
                    &.disabled {
                        &:hover {
                            background-color: $color_tapa_approx;
                            border-color: $color_fuscous_gray_approx;
                        }
                        &:focus {
                            background-color: $color_tapa_approx;
                            border-color: $color_fuscous_gray_approx;
                        }
                        &.focus {
                            background-color: $color_tapa_approx;
                            border-color: $color_fuscous_gray_approx;
                        }
                    }
                    &.highlighted[disabled] {
                        &:hover {
                            background-color: $color_tapa_approx;
                            border-color: $color_fuscous_gray_approx;
                        }
                        &:focus {
                            background-color: $color_tapa_approx;
                            border-color: $color_fuscous_gray_approx;
                        }
                        &.focus {
                            background-color: $color_tapa_approx;
                            border-color: $color_fuscous_gray_approx;
                        }
                    }
                }
                &.selected[disabled] {
                    &:hover {
                        background-color: $color_tapa_approx;
                        border-color: $color_fuscous_gray_approx;
                    }
                    &:focus {
                        background-color: $color_tapa_approx;
                        border-color: $color_fuscous_gray_approx;
                    }
                    &.focus {
                        background-color: $color_tapa_approx;
                        border-color: $color_fuscous_gray_approx;
                    }
                }
                &.active {
                    @extend %extend_23;
                    &.highlighted {
                        @extend %extend_23;
                        &:focus {
                            @extend %extend_24;
                        }
                        &.focus {
                            @extend %extend_24;
                        }
                        &:hover {
                            @extend %extend_25;
                        }
                        &:active {
                            @extend %extend_26;
                            &:hover {
                                @extend %extend_27;
                            }
                            &:focus {
                                @extend %extend_27;
                            }
                            &.focus {
                                @extend %extend_27;
                            }
                        }
                        &.active {
                            @extend %extend_26;
                            &:hover {
                                @extend %extend_27;
                            }
                            &:focus {
                                @extend %extend_27;
                            }
                            &.focus {
                                @extend %extend_27;
                            }
                        }
                        &.disabled {
                            &:hover {
                                background-color: $color_steel_blue_approx;
                                border-color: $color_astral_approx;
                            }
                            &:focus {
                                background-color: $color_steel_blue_approx;
                                border-color: $color_astral_approx;
                            }
                            &.focus {
                                background-color: $color_steel_blue_approx;
                                border-color: $color_astral_approx;
                            }
                        }
                    }
                    &:focus {
                        @extend %extend_24;
                    }
                    &.focus {
                        @extend %extend_24;
                    }
                    &:hover {
                        @extend %extend_25;
                    }
                    &:active {
                        @extend %extend_26;
                        &:hover {
                            @extend %extend_27;
                        }
                        &:focus {
                            @extend %extend_27;
                        }
                        &.focus {
                            @extend %extend_27;
                        }
                    }
                    &.active {
                        @extend %extend_26;
                        &:hover {
                            @extend %extend_27;
                        }
                        &:focus {
                            @extend %extend_27;
                        }
                        &.focus {
                            @extend %extend_27;
                        }
                    }
                    &.disabled {
                        &:hover {
                            background-color: $color_steel_blue_approx;
                            border-color: $color_astral_approx;
                        }
                        &:focus {
                            background-color: $color_steel_blue_approx;
                            border-color: $color_astral_approx;
                        }
                        &.focus {
                            background-color: $color_steel_blue_approx;
                            border-color: $color_astral_approx;
                        }
                    }
                    &.highlighted[disabled] {
                        &:hover {
                            background-color: $color_steel_blue_approx;
                            border-color: $color_astral_approx;
                        }
                        &:focus {
                            background-color: $color_steel_blue_approx;
                            border-color: $color_astral_approx;
                        }
                        &.focus {
                            background-color: $color_steel_blue_approx;
                            border-color: $color_astral_approx;
                        }
                    }
                }
                &.active[disabled] {
                    &:hover {
                        background-color: $color_steel_blue_approx;
                        border-color: $color_astral_approx;
                    }
                    &:focus {
                        background-color: $color_steel_blue_approx;
                        border-color: $color_astral_approx;
                    }
                    &.focus {
                        background-color: $color_steel_blue_approx;
                        border-color: $color_astral_approx;
                    }
                }
                span {
                    display: block;
                    width: 23%;
                    height: 54px;
                    line-height: 54px;
                    float: left;
                    margin: 1%;
                    cursor: pointer;
                    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
                    border-radius: 4px;
                    &:hover {
                        background: $color_gallery_approx;
                    }
                    &.focused {
                        background: $color_gallery_approx;
                    }
                    &.disabled {
                        @extend %extend_28;
                        &:hover {
                            @extend %extend_28;
                        }
                    }
                    &.active {
                        @extend %extend_29;
                        &:hover {
                            @extend %extend_29;
                            @extend %extend_31;
                            &:focus {
                                @extend %extend_30;
                            }
                            &.focus {
                                @extend %extend_30;
                            }
                            &:hover {
                                @extend %extend_31;
                            }
                            &:active {
                                @extend %extend_32;
                                &:hover {
                                    @extend %extend_33;
                                }
                                &:focus {
                                    @extend %extend_33;
                                }
                                &.focus {
                                    @extend %extend_33;
                                }
                            }
                            &.active {
                                @extend %extend_32;
                                &:hover {
                                    @extend %extend_33;
                                }
                                &:focus {
                                    @extend %extend_33;
                                }
                                &.focus {
                                    @extend %extend_33;
                                }
                            }
                            &.disabled {
                                &:hover {
                                    background-color: $color_steel_blue_approx;
                                    border-color: $color_astral_approx;
                                }
                                &:focus {
                                    background-color: $color_steel_blue_approx;
                                    border-color: $color_astral_approx;
                                }
                                &.focus {
                                    background-color: $color_steel_blue_approx;
                                    border-color: $color_astral_approx;
                                }
                            }
                        }
                        &.disabled {
                            @extend %extend_29;
                            &:hover {
                                @extend %extend_29;
                                @extend %extend_31;
                                background-color: $color_steel_blue_approx;
                                border-color: $color_astral_approx;
                                &:focus {
                                    @extend %extend_30;
                                }
                                &.focus {
                                    @extend %extend_30;
                                }
                                &:hover {
                                    @extend %extend_31;
                                }
                                &:active {
                                    @extend %extend_32;
                                    &:hover {
                                        @extend %extend_33;
                                    }
                                    &:focus {
                                        @extend %extend_33;
                                    }
                                    &.focus {
                                        @extend %extend_33;
                                    }
                                }
                                &.active {
                                    @extend %extend_32;
                                    &:hover {
                                        @extend %extend_33;
                                    }
                                    &:focus {
                                        @extend %extend_33;
                                    }
                                    &.focus {
                                        @extend %extend_33;
                                    }
                                }
                                &.disabled {
                                    &:hover {
                                        background-color: $color_steel_blue_approx;
                                        border-color: $color_astral_approx;
                                    }
                                    &:focus {
                                        background-color: $color_steel_blue_approx;
                                        border-color: $color_astral_approx;
                                    }
                                    &.focus {
                                        background-color: $color_steel_blue_approx;
                                        border-color: $color_astral_approx;
                                    }
                                }
                            }
                            &:focus {
                                @extend %extend_30;
                                background-color: $color_steel_blue_approx;
                                border-color: $color_astral_approx;
                            }
                            &.focus {
                                @extend %extend_30;
                                background-color: $color_steel_blue_approx;
                                border-color: $color_astral_approx;
                            }
                            &:active {
                                @extend %extend_32;
                                &:hover {
                                    @extend %extend_33;
                                }
                                &:focus {
                                    @extend %extend_33;
                                }
                                &.focus {
                                    @extend %extend_33;
                                }
                            }
                            &.active {
                                @extend %extend_32;
                                &:hover {
                                    @extend %extend_33;
                                }
                                &:focus {
                                    @extend %extend_33;
                                }
                                &.focus {
                                    @extend %extend_33;
                                }
                            }
                            &.disabled {
                                &:hover {
                                    background-color: $color_steel_blue_approx;
                                    border-color: $color_astral_approx;
                                }
                                &:focus {
                                    background-color: $color_steel_blue_approx;
                                    border-color: $color_astral_approx;
                                }
                                &.focus {
                                    background-color: $color_steel_blue_approx;
                                    border-color: $color_astral_approx;
                                }
                            }
                            &:hover[disabled] {
                                &:hover {
                                    background-color: $color_steel_blue_approx;
                                    border-color: $color_astral_approx;
                                }
                                &:focus {
                                    background-color: $color_steel_blue_approx;
                                    border-color: $color_astral_approx;
                                }
                                &.focus {
                                    background-color: $color_steel_blue_approx;
                                    border-color: $color_astral_approx;
                                }
                            }
                        }
                        &:focus {
                            @extend %extend_30;
                        }
                        &.focus {
                            @extend %extend_30;
                        }
                        &:active {
                            @extend %extend_32;
                            &:hover {
                                @extend %extend_33;
                            }
                            &:focus {
                                @extend %extend_33;
                            }
                            &.focus {
                                @extend %extend_33;
                            }
                        }
                        &.active {
                            @extend %extend_32;
                            &:hover {
                                @extend %extend_33;
                            }
                            &:focus {
                                @extend %extend_33;
                            }
                            &.focus {
                                @extend %extend_33;
                            }
                        }
                        &:hover[disabled] {
                            &:hover {
                                background-color: $color_steel_blue_approx;
                                border-color: $color_astral_approx;
                            }
                            &:focus {
                                background-color: $color_steel_blue_approx;
                                border-color: $color_astral_approx;
                            }
                            &.focus {
                                background-color: $color_steel_blue_approx;
                                border-color: $color_astral_approx;
                            }
                        }
                        &.disabled[disabled] {
                            &:hover {
                                background-color: $color_steel_blue_approx;
                                border-color: $color_astral_approx;
                            }
                            &:focus {
                                background-color: $color_steel_blue_approx;
                                border-color: $color_astral_approx;
                            }
                            &.focus {
                                background-color: $color_steel_blue_approx;
                                border-color: $color_astral_approx;
                            }
                        }
                    }
                    &.active[disabled] {
                        &:hover {
                            background-color: $color_steel_blue_approx;
                            border-color: $color_astral_approx;
                        }
                        &:focus {
                            background-color: $color_steel_blue_approx;
                            border-color: $color_astral_approx;
                        }
                        &.focus {
                            background-color: $color_steel_blue_approx;
                            border-color: $color_astral_approx;
                        }
                    }
                    &.old {
                        color: $color_tapa_approx;
                    }
                    &.new {
                        color: $color_tapa_approx;
                    }
                }
                &.day, &.month, &.year, &.decade{
                    &:not(.disabled){
                        background-color: transparent;
                        day{
                            border: 1px solid $color_burgundy_approx;
                            color: $color_burgundy_approx;
                            border-radius: 4px;
                            width: 100px;
                            height: 35px;
                            display: inherit;
                            padding: 5px 0px;
                        }


                        &:hover, &.active {
                            background-color: transparent;
                            day {
                                background: $color_burgundy_approx;
                                color: white;
                            }

                        }

                        &.old, &.new{
                            day{
                                color: $color_pale_slate_approx;
                            }
                        }

                    }
                }
                &.day:hover {
                    background: $color_gallery_approx;
                    cursor: pointer;
                }
            }
            th {
                @extend %extend_1;
            }
        }
    }
    .datepicker-switch {
        font-size: 20px;
        width: 145px;
        cursor: pointer;
        &:hover {
            background: $color_gallery_approx;
        }
        border-radius: 0px;
    }
    .prev {
        cursor: pointer;
        &:hover {
            background: $color_gallery_approx;
        }
        border-radius: 4px 0px 0px 4px;
        font-size: 22px;
        padding: 0px;
    }
    .next {
        cursor: pointer;
        &:hover {
            background: $color_gallery_approx;
        }
        border-radius: 0px 4px 4px 0px;
        font-size: 22px;
        padding: 0px;
    }
    .cw {
        font-size: 10px;
        width: 12px;
        padding: 0 2px 0 5px;
        vertical-align: middle;
    }
    tfoot tr th {
        cursor: pointer;
        &:hover {
            background: $color_gallery_approx;
        }
    }
}
.datepicker-inline {
}
.datepicker-days, .datepicker-months, .datepicker-years, .datepicker-decades, .datepicker-centuries {
    display: inline-block;
    width: 100%;

    .table-condensed {
        thead {
            tr:nth-of-type(2) {
                th {
                    background-color: $color_burgundy_approx;
                    color: white;
                }
            }

        }

    }
}
.datepicker-rtl {
    direction: rtl;
    &.dropdown-menu {
        left: auto;
    }
    table tr td span {
        float: right;
    }
}
.datepicker-dropdown {
    top: 0;
    left: 0;
    padding: 4px;
    &:before {
        content: '';
        display: inline-block;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid $black_15;
        border-top: 0;
        border-bottom-color: $black_20;
        position: absolute;
    }
    &:after {
        content: '';
        display: inline-block;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $white;
        border-top: 0;
        position: absolute;
    }
    &.datepicker-orient-left {
        &:before {
            left: 6px;
        }
        &:after {
            left: 7px;
        }
    }
    &.datepicker-orient-right {
        &:before {
            right: 6px;
        }
        &:after {
            right: 7px;
        }
    }
    &.datepicker-orient-bottom {
        &:before {
            top: -7px;
        }
        &:after {
            top: -6px;
        }
    }
    &.datepicker-orient-top {
        &:before {
            bottom: -7px;
            border-bottom: 0;
            border-top: 7px solid $black_15;
        }
        &:after {
            bottom: -6px;
            border-bottom: 0;
            border-top: 6px solid $white;
        }
    }
}
.input-daterange {
    width: 100%;
    input {
        text-align: center;
        &:first-child {
            //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
            border-radius: 3px 0 0 3px;
        }
        &:last-child {
            //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
            border-radius: 0 3px 3px 0;
        }
    }
    .input-group-addon {
        width: auto;
        min-width: 16px;
        padding: 4px 5px;
        line-height: 1.42857143;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 1px 0 $white;
        border-width: 1px 0;
        margin-left: -5px;
        margin-right: -5px;
    }
}
.table-striped .datepicker table tr {
    td {
        background-color: transparent;
    }
    th {
        background-color: transparent;
    }
}
fieldset[disabled] .datepicker table tr td {
    &.highlighted {
        &:hover {
            background-color: $color_link_water_approx;
            border-color: $color_seagull_approx;
        }
        &:focus {
            background-color: $color_link_water_approx;
            border-color: $color_seagull_approx;
        }
        &.focus {
            background-color: $color_link_water_approx;
            border-color: $color_seagull_approx;
        }
    }
    &.today {
        &:hover {
            background-color: $color_cherokee_approx;
            border-color: $color_saffron_approx;
        }
        &:focus {
            background-color: $color_cherokee_approx;
            border-color: $color_saffron_approx;
        }
        &.focus {
            background-color: $color_cherokee_approx;
            border-color: $color_saffron_approx;
        }
    }
    &.range {
        &:hover {
            background-color: $color_gallery_approx;
            border-color: $color_pink_swan_approx;
        }
        &:focus {
            background-color: $color_gallery_approx;
            border-color: $color_pink_swan_approx;
        }
        &.focus {
            background-color: $color_gallery_approx;
            border-color: $color_pink_swan_approx;
        }
        &.highlighted {
            &:hover {
                background-color: $color_mystic_approx;
                border-color: $color_casper_approx;
            }
            &:focus {
                background-color: $color_mystic_approx;
                border-color: $color_casper_approx;
            }
            &.focus {
                background-color: $color_mystic_approx;
                border-color: $color_casper_approx;
            }
        }
        &.today {
            &:hover {
                background-color: $color_rob_roy_approx;
                border-color: $color_buttercup_approx;
            }
            &:focus {
                background-color: $color_rob_roy_approx;
                border-color: $color_buttercup_approx;
            }
            &.focus {
                background-color: $color_rob_roy_approx;
                border-color: $color_buttercup_approx;
            }
        }
    }
    &.selected {
        &:hover {
            background-color: $color_tapa_approx;
            border-color: $color_fuscous_gray_approx;
        }
        &.highlighted {
            &:hover {
                background-color: $color_tapa_approx;
                border-color: $color_fuscous_gray_approx;
            }
            &:focus {
                background-color: $color_tapa_approx;
                border-color: $color_fuscous_gray_approx;
            }
            &.focus {
                background-color: $color_tapa_approx;
                border-color: $color_fuscous_gray_approx;
            }
        }
        &:focus {
            background-color: $color_tapa_approx;
            border-color: $color_fuscous_gray_approx;
        }
        &.focus {
            background-color: $color_tapa_approx;
            border-color: $color_fuscous_gray_approx;
        }
    }
    &.active {
        &:hover {
            background-color: $color_steel_blue_approx;
            border-color: $color_astral_approx;
        }
        &.highlighted {
            &:hover {
                background-color: $color_steel_blue_approx;
                border-color: $color_astral_approx;
            }
            &:focus {
                background-color: $color_steel_blue_approx;
                border-color: $color_astral_approx;
            }
            &.focus {
                background-color: $color_steel_blue_approx;
                border-color: $color_astral_approx;
            }
        }
        &:focus {
            background-color: $color_steel_blue_approx;
            border-color: $color_astral_approx;
        }
        &.focus {
            background-color: $color_steel_blue_approx;
            border-color: $color_astral_approx;
        }
    }
    span.active {
        &:hover {
            background-color: $color_steel_blue_approx;
            border-color: $color_astral_approx;
            &:hover {
                background-color: $color_steel_blue_approx;
                border-color: $color_astral_approx;
            }
            &:focus {
                background-color: $color_steel_blue_approx;
                border-color: $color_astral_approx;
            }
            &.focus {
                background-color: $color_steel_blue_approx;
                border-color: $color_astral_approx;
            }
        }
        &.disabled {
            &:hover {
                background-color: $color_steel_blue_approx;
                border-color: $color_astral_approx;
                &:hover {
                    background-color: $color_steel_blue_approx;
                    border-color: $color_astral_approx;
                }
                &:focus {
                    background-color: $color_steel_blue_approx;
                    border-color: $color_astral_approx;
                }
                &.focus {
                    background-color: $color_steel_blue_approx;
                    border-color: $color_astral_approx;
                }
            }
            &:focus {
                background-color: $color_steel_blue_approx;
                border-color: $color_astral_approx;
            }
            &.focus {
                background-color: $color_steel_blue_approx;
                border-color: $color_astral_approx;
            }
        }
        &:focus {
            background-color: $color_steel_blue_approx;
            border-color: $color_astral_approx;
        }
        &.focus {
            background-color: $color_steel_blue_approx;
            border-color: $color_astral_approx;
        }
    }
}
.input-group.date .input-group-addon {
    cursor: pointer;
}