
// Body
//$body-bg: #a39b98;
$body-bg: #ffffff;
$panel-bg: transparent;
// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;
$navbar-border-radius: 0px;

    // Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$state-warning-text: #ffc339;
$brand-danger: #990726;


    // Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: 'Noto Sans', sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #5d4037;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;
$btn-default-border: $brand-danger;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: $brand-danger;
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

$list-group-active-bg:          $brand-danger;

