*,
*:after,
*::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.ac-custom {
}

.ac-custom h2 {
    font-size: 3em;
    font-weight: 300;
    padding: 0 0 0.5em;
    margin: 0 0 30px;
}

.ac-custom ul,
.ac-custom ol {
    list-style: none;
    padding: 0;
    margin: 0 auto;
}

.ac-custom li {
    margin: 0 auto;
    padding: 2em 0;
    position: relative;
}

.ac-custom label {
    display: inline-block;
    position: relative;
    font-size: 2em;
    padding: 0 0 0 80px;
    vertical-align: top;
    color: rgba(0,0,0,0.2);
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}
.ac-custom.ac-radio label{
    color: lighten( $text-color, 15%);
}

.ac-custom input[type="checkbox"],
.ac-custom input[type="radio"],
.ac-custom label::before {
    width: 50px;
    height: 50px;
    top: 50%;
    left: 0;
    margin-top: -25px;
    position: absolute;
    cursor: pointer;
}

.ac-custom input[type="checkbox"],
.ac-custom input[type="radio"] {
    opacity: 0;
    -webkit-appearance: none;
    display: inline-block;
    vertical-align: middle;
    z-index: 100;
}

.ac-custom label::before {
    content: '';
    border: 4px solid #fff;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
}

.ac-custom.ac-radio label::before {
    border: 4px solid $body-bg;
}

.ac-radio label::before {
    border-radius: 50%;
}

.ac-custom input[type="checkbox"]:checked + label {
    color: #fff;
}

.ac-custom input[type="radio"]:checked + label {
    color: $text-color;
}

.ac-custom input[type="checkbox"]:checked + label::before,
.ac-custom input[type="radio"]:checked + label::before {
    opacity: 0.8;
}

/* General SVG and path styles */

.ac-custom svg {
    position: absolute;
    width: 35px;
    height: 35px;
    top: 50%;
    margin-top: -20px;
    left: 7px;
    pointer-events: none;
}

.ac-custom svg path {
    stroke: $brand-danger;
    stroke-width: 13px;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
}

/* Specific input, SVG and path styles */

/* Circle */
.ac-circle input[type="checkbox"],
.ac-circle input[type="radio"],
.ac-circle label::before {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    left: 10px;
    position: absolute;
}

.ac-circle label::before {
    background-color: #fff;
    border: none;
}

.ac-circle svg {
    width: 70px;
    height: 70px;
    margin-top: -35px;
    left: -10px;
}

.ac-circle svg path {
    stroke-width: 5px;
}

/* Box Fill */
.ac-boxfill svg path {
    stroke-width: 8px;
}

/* Swirl */
.ac-swirl svg path {
    stroke-width: 8px;
}

/* List */
.ac-list ol {
    list-style: decimal;
    list-style-position: inside;
}

.ac-list ol li {
    font-size: 2em;
    padding: 1em 1em 0 2em;
    text-indent: -40px;
}

.ac-list ol li label {
    font-size: 1em;
    text-indent: 0;
    padding-left: 30px;
}

.ac-list label::before {
    display: none;
}

.ac-list svg {
    width: 100%;
    height: 80px;
    left: 0;
    top: 1.2em;
    margin-top: 0px;
}

.ac-list svg path {
    stroke-width: 4px;
}

